import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Loading from '../Components/Loading';
import ChoosePet from './ChoosePet';
import Disclaimer from './Disclaimer';
import Diagnosis from '../Components/Diagnosis';
import Question from '../Components/Question';
import BaseProgressBar from '../Components/UI/BaseProgressBar';
import Assistant from '../Components/Assistant';
import ActionsBar from './ActionsBar';

const Workspace = (props) => {
  const { t } = useTranslation();
  const {
    pet,
    onPetSelect,
    disclaimerAccepted,
    setDisclaimerAccepted,
    progress,
    isInit,
    isLoading,
    isFirstStep,
    isLastStep,
    currentStep,
    onOptionSelect,
    onBack,
    onRestart
  } = props;
  const isNotReady = isInit || !currentStep;

  const assistantMessage = () => {
    if (isFirstStep) return t(`common.welcome.${pet}`);
    if (isLastStep) return t(`common.action-types.${currentStep.action_type}`);
    return undefined;
  };

  if (!pet) return <ChoosePet onSelect={onPetSelect} progress={progress} />;
  if (!disclaimerAccepted) return <Disclaimer progress={progress} setDisclaimerAccepted={setDisclaimerAccepted} onBack={onBack} />;

  return (
    <div className="content mb-5">
      <div className="container">
        <BaseProgressBar value={props.progress} rounded />
        <Assistant type={isLastStep ? currentStep.action_type : undefined} message={assistantMessage()} />
        <ActionsBar canBack={true} onBack={onBack} canRestart={true} onRestart={onRestart} />

        {isNotReady ? (
          <Loading />
        ) : (
          <>
            {isLastStep ? (
              <Diagnosis step={currentStep} />
            ) : (
              <Question isFirstStep={isFirstStep} currentStep={currentStep} onSelect={onOptionSelect} isLoading={isLoading} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

Workspace.propTypes = {
  pet: PropTypes.string,
  onPetSelect: PropTypes.func,
  disclaimerAccepted: PropTypes.bool,
  setDisclaimerAccepted: PropTypes.func,
  progress: PropTypes.number,
  isInit: PropTypes.bool,
  currentStep: PropTypes.object,
  isLoading: PropTypes.bool,
  isFirstStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  onOptionSelect: PropTypes.func,
  onBack: PropTypes.func
};

export default Workspace;
