import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Assistant = ({ type, message }) => {
  const { t } = useTranslation();
  const printMessage = message || t('common.default-assistant');

  const bubbleMapper = {
    pet_ok: 'bubble--info',
    self_care: 'bubble--info',
    consultation: 'bubble--warning',
    some_treatment: 'bubble--warning',
    contact_vet: 'bubble--danger',
    urgent: 'bubble--danger',
    // To be removed:
    medium_priority: 'bubble--warning',
    high_priority: 'bubble--warning',
  };

  const cssClass = () => {
    if (!type) return 'bubble--info';
    return bubbleMapper[type];
  };

  const imgPath = () => {
    if (type === 'urgent') return '/img/dog-mini-web-doc.svg';
    return '/img/dog-mini-web-normal.svg';
  };

  return (
    <div className="bubble-container">
      <div className={`bubble ${cssClass()}`}>
        <img
          className="bubble-image"
          src={imgPath()}
          srcSet={imgPath()}
          alt={printMessage}
        />

        <div className="bubble-content">
          <div className="bubble-text">
            {printMessage}
          </div>
        </div>
      </div>
    </div>
  )
};

Assistant.defaultProps = {
  type: 'pet_ok',
};

Assistant.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default Assistant;
