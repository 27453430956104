import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '../Components/UI/BaseModal';
import { useTranslation } from 'react-i18next';

const RestartModal = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      show
      content={{
        imageUrl: '/img/dog-doc.svg',
        title: t('modals.restart.title'),
        description: t('modals.restart.description'),
        submitText: t('common.actions.restart'),
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

RestartModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RestartModal;
