import React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { toLocalDatetime } from '../../utils/datetime';

const NextAvailableTimeSlot = ({ nextAvailableTime }) => {
  const { i18n, t } = useTranslation();

  const datetime = useCallback(() => {
    const { date, time } = toLocalDatetime(nextAvailableTime.date, nextAvailableTime.time);
    const today = date === dayjs(new Date()).format('YYYY-MM-DD');

    if (today) {
      return `${t('common.today')}, ${time}`;
    } else {
      return `${dayjs(date).format('D MMMM')}, ${time}`;
    }
  }, [nextAvailableTime, t]);

  return (
    <>
      {nextAvailableTime ? (
        <div className="next-available-time-slot">
          <span className="vetAvatar">
            <img src={nextAvailableTime.vet.img} alt={`${nextAvailableTime.vet.name} avatar`} width="54" height="54" />
          </span>

          <div className="details">
            <p className="availableTimeCaption">{t('common.next-available-time')}</p>
            <div className="detailsRow">
              <p className="datetime" variant="h3">
                {datetime()}
              </p>
            </div>
            <div className="vet-name">
              {nextAvailableTime.vet.title[i18n.language]} {nextAvailableTime.vet.name}
            </div>
          </div>
        </div>
      ) : (
        <div className="next-available-time-slot">
          <div className="vetAvatar bone round" style={{ width: 54, height: 54 }}></div>

          <div className="details">
            <p className="availableTimeCaption bone"></p>

            <div className="detailsRow">
              <p className="datetime bone" variant="h3"></p>
            </div>

            <div className="vet-name bone"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default NextAvailableTimeSlot;
