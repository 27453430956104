import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

const BaseInput = React.forwardRef(
  ({
    className,
    onClick,
    value,
    label,
    placeholder = 'placeholder',
    type = 'text',
    persistentPlaceholder = false,
    disabled,
    readOnly,
    isInvalid,
    hint,
    onChange,
  }, ref) => {
    return (
      <FloatingLabel ref={ref} className={`base-form-floating ${className}`} label={label} onClick={onClick}>
        <Form.Control
          className={`base-input ${persistentPlaceholder ? 'persistent-placeholder' : ''}`}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          isInvalid={isInvalid}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">{hint}</Form.Control.Feedback>
      </FloatingLabel>
    );
  }
);

export default BaseInput;
