import React from 'react';
import PropTypes from 'prop-types';

const BaseProgressBar = ({ className, value = 0, rounded = false, indeterminate = false }) => {
  const classNamesList = `base-progress-bar ${rounded ? 'base-progress-bar--rounded' : ''} ${
    indeterminate ? 'base-progress-bar--indeterminate' : ''
  } ${className || ''}`;

  return (
    <div className={classNamesList}>
      <div className="base-progress-bar-background" />
      <div className="base-progress-bar-progress" style={{ width: `${indeterminate ? 100 : value}%` }} />
    </div>
  );
};

BaseProgressBar.propTypes = {
  progress: PropTypes.number
};

export default BaseProgressBar;
