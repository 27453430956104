import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const AppBarMenu = ({ className, menu, onClick }) => {
  const location = useLocation();

  return (
    <div className={`app-bar-menu ${className || ''}`}>
      {menu &&
        menu.map((menuItem) => {
          const classNamesList = `app-bar-menu-item ${menuItem.href === location.pathname ? 'app-bar-menu-item--active' : ''}`;

          if (menuItem.external) {
            return (
              <a key={menuItem.key} className={classNamesList} href={menuItem.href} target="_blank" rel="noreferrer" onClick={onClick}>
                <span>{menuItem.text}</span>
              </a>
            );
          } else {
            return (
              <Link className={classNamesList} key={menuItem.key} to={{ pathname: menuItem.href }} onClick={onClick}>
                {menuItem.text}
              </Link>
            );
          }
        })}
    </div>
  );
};

export default AppBarMenu;
