import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import CheckerPage from './Pages/Checker';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en.json';
import nl from '../locales/nl.json';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const gtmParams = { id: 'GTM-KZ57L6C' };

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    nl: { translation: nl }
  },
  react: {
    useSuspense: false
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

const App = () => {
  const [cookies, setCookie] = useCookies(['cpc_lang']);
  const queryString = window.location.search;
  const urlParams = useMemo(() => {
    return new URLSearchParams(queryString);
  }, [queryString]);

  useEffect(() => {
    const validLangs = ['en', 'nl'];
    const langFromURL = urlParams.get('lang');
    const promoFromURL = urlParams.get('promo');

    // Check if lang is passed in URL params (set cookie in this case).
    if (validLangs.includes(langFromURL)) {
      setCookie('cpc_lang', langFromURL, { path: '/', maxAge: 2592000 }); // 1 month
    }

    // The same for OHRA promo codes.
    if (promoFromURL) {
      setCookie('cpc-promo-code', promoFromURL, {
        path: '/',
        maxAge: 2592000, // 1 month
        domain: process.env.NODE_ENV === 'production' ? '.cooperpetcare.com' : '.site.local'
      });
    }

    // Check if lang cookie is defined.
    const lang = cookies['cpc_lang'];
    if (validLangs.includes(lang)) i18n.changeLanguage(lang);
  }, [urlParams, cookies, setCookie]);

  return (
    <Router>
      <GTMProvider state={gtmParams}>
        <Switch>
          <Route path="/checker">
            <CheckerPage />
          </Route>
          <Route path="/call">
            <CheckerPage />
          </Route>
          <Route path="/">
            <CheckerPage />
          </Route>
        </Switch>
      </GTMProvider>
    </Router>
  );
};

export default App;
