import React from 'react';
import PropTypes from 'prop-types';

const ButtonsOption = ({ options, onSelect }) => {
  const selectWithDelay = option => setTimeout(() => onSelect(option), 200);

  return (
    <div className="base-btn-group" role="group">
      {options.map(option => {
        return (
          <button
            key={option.id}
            onClick={() => selectWithDelay(option)}
            className="base-btn btn btn-primary"
          >
            {option.title}
          </button>
        )
      })}

    </div>
  )
};

ButtonsOption.defaultProps = {
  options: [],
};

ButtonsOption.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
};

export default ButtonsOption;
