import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseProgressBar from '../Components/UI/BaseProgressBar';
import Assistant from '../Components/Assistant';
import ActionsBar from '../Components/ActionsBar';

const Disclaimer = ({ progress, setDisclaimerAccepted, onBack }) => {
  const { t } = useTranslation();

  return (
    <div className="content">
      <div className="container">
        <BaseProgressBar value={progress} />
        <Assistant message={t('pages.disclaimer.assistant')} />
        <ActionsBar canBack={true} onBack={onBack} />

        <div className="step step--choose-pet">
          <h3 className="step-info text-center">{t('pages.disclaimer.title')}</h3>

          <div className="base-btn-group btn-group" role="group">
            <button onClick={() => setDisclaimerAccepted(true)} className="base-btn btn btn-primary">
              {t('pages.disclaimer.cta')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Disclaimer.propTypes = {
  progress: PropTypes.number,
  setDisclaimerAccepted: PropTypes.func
};

export default Disclaimer;
