import React, { useState, useEffect } from 'react';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { GTMCategory, GTMEvents } from '../lib/consts';
import NextAvailableTimeSlot from './NextAvailableTimeSlot';

const Diagnosis = (props) => {
  const { t } = useTranslation();
  const sendDataToGTM = useGTMDispatch();
  useEffect(() => {
    sendDataToGTM({
      event: 'event',
      eventProps: {
        category: GTMCategory,
        action: GTMEvents.resultVisit
      }
    });
  }, [sendDataToGTM]);

  const onCtaClick = () => {
    sendDataToGTM({
      event: 'event',
      eventProps: {
        category: GTMCategory,
        action: GTMEvents.ctaClicked
      }
    });
    window.open(`https://account.cooperpetcare.com/?lang=${i18n.language}`);
  };

  const [nextAvailableTime, setNextAvailableTime] = useState(null);
  const getNextAvailableTime = async () => {
    try {
      const req = await fetch(`https://api.cooperpetcare.com/next_call`, { method: 'get' });
      const resp = await req.json();
      setNextAvailableTime(resp);
    } catch (err) {
      console.log('Error:', err);
    }
  };

  useEffect(() => {
    if (!nextAvailableTime) {
      getNextAvailableTime();
    }
  }, [nextAvailableTime]);

  return (
    <>
      <div className="diagnosis">
        {props.step.problem && (
          <div className="diagnosis-content diagnosis-content--info" dangerouslySetInnerHTML={{ __html: props.step.problem }} />
        )}

        <div className="diagnosis-vet-widget vet-widget">
          <h3 className="vet-widget-title">{t('pages.diagnosis.vet-widget.title')}</h3>
          <div className="vet-widget-content">
            <div className="vet-widget-time">
              <NextAvailableTimeSlot className="vet-widget-next-time" nextAvailableTime={nextAvailableTime} />
              <button className="base-btn btn-rounded btn-icon btn btn-success" type="button" onClick={onCtaClick}>
                {t('common.talk-to-vet')}
                <img
                  srcSet="/img/icon-arrow-right.svg"
                  src="/img/icon-arrow-right.svg"
                  alt={t('common.talk-to-vet')}
                  width="20"
                  height="20"
                />
              </button>
            </div>

            <ul className="vet-widget-list">
              <li>{t('pages.diagnosis.vet-widget.description.1')}</li>
              <li>{t('pages.diagnosis.vet-widget.description.2')}</li>
              <li>{t('pages.diagnosis.vet-widget.description.3')}</li>
            </ul>
          </div>
        </div>

        {props.step.first_aid && (
          <div
            className="diagnosis-content diagnosis-content--recommendations"
            dangerouslySetInnerHTML={{ __html: props.step.first_aid }}
          />
        )}
      </div>
    </>
  );
};

Diagnosis.propTypes = {
  step: PropTypes.object
};

export default Diagnosis;
