import React from 'react';
import PropTypes from 'prop-types';
import DropdownOption from './DropdownOption';
import ButtonsOption from './ButtonsOption';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';

const Options = (props) => {
  const { t } = useTranslation();

  if (props.isLoading) return <Loading />;
  if (!props.options.length) return <div className="text-center">{t('common.no-options')}</div>;

  return props.options.length > 3 ? <DropdownOption {...props} filterDisabled={!props.isFirstStep} /> : <ButtonsOption {...props} />;
};

Options.defaultProps = {
  options: [],
  isLoading: false
};

Options.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  isFirstStep: PropTypes.bool
};

export default Options;
