import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import RestartModal from '../Modals/Restart';

const ActionsBar = ({ canBack = false, canRestart = false, onBack, onRestart }) => {
  const history = useHistory();
  const defaultBack = () => history.goBack();

  const [showRestartModal, setShowRestartModal] = useState(false);
  const restart = () => {
    onRestart();
    setShowRestartModal(false);
  };

  return (
    <>
      {(canBack || canRestart) && (
        <div className="actions-bar">
          {canBack && (
            <button className="actions-bar-btn btn-back" onClick={onBack || defaultBack}>
              <ReactSVG src="/img/icon-back.svg" />
            </button>
          )}
          {canRestart && (
            <button className="actions-bar-btn btn-refresh" onClick={() => setShowRestartModal(true)}>
              <ReactSVG src="/img/icon-refresh.svg" />
            </button>
          )}
        </div>
      )}

      {showRestartModal && <RestartModal onSubmit={restart} onCancel={() => setShowRestartModal(false)} />}
    </>
  );
};

export default ActionsBar;
