import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseSelect from '../UI/BaseSelect';

const DropdownOption = ({ options, filterDisabled = false, onSelect }) => {
  const { t } = useTranslation();
  const filteredOptions = options.filter((option) => !!option.title);

  const dropdownItems =
    filteredOptions && filteredOptions.sort((a, b) => a.title.localeCompare(b.title)).map((o) => ({ value: o.id, text: o.title }));

  const onSelectItem = (id) => {
    const option = options.find((o) => o.id === id);
    onSelect(option);
  };

  return (
    <div className="text-center">
      <BaseSelect
        label={t('common.symptom')}
        placeholder={t('common.placeholder')}
        persistentPlaceholder={true}
        options={dropdownItems}
        onSelect={onSelectItem}
        filterDisabled={filterDisabled}
      ></BaseSelect>
    </div>
  );
};

DropdownOption.defaultProps = {
  options: []
};

DropdownOption.propTypes = {
  options: PropTypes.array,
  filterDisabled: PropTypes.bool,
  onSelect: PropTypes.func
};

export default DropdownOption;
