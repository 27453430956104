import React, { useState, useCallback, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import AppBarMenu from './AppBarMenu';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import BaseButton from '../Components/UI/BaseButton';
import i18n from 'i18next';
import { useMediaQuery, tabletQuery } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

const AppBar = ({ flat }) => {
  const { t } = useTranslation();
  const [menuOpened, setMenuOpened] = useState(false);
  const newLang = ['en', 'nl'].find((lang) => lang !== i18n.language);

  const tablet = useMediaQuery(tabletQuery);

  const menu = useMemo(() => {
    return [
      {
        key: 'blog',
        text: t('menu.blog'),
        href: 'https://cooperpetcare.com/blog/',
        external: true
      },
      ...(tablet
        ? [
            {
              key: 'lang',
              text: (
                <span>
                  <ReactSVG src={`/img/icon-flag-${newLang}.svg`} />
                  {t(`common.lang.${newLang}`)}
                </span>
              ),
              href: ''
            }
          ]
        : [])
    ];
  }, [t, newLang, tablet]);

  const onMenuButtonClick = useCallback(() => setMenuOpened(!menuOpened), [menuOpened]);
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['cpc_lang']);

  const changeLang = useCallback(() => {
    i18n.changeLanguage(newLang);
    history.push(`/?lang=${newLang}`);
    setCookie('cpc_lang', newLang, { path: '/', maxAge: 2592000 }); // 1 month
  }, [history, newLang, setCookie]);

  const onMobileMenuItemClick = useCallback(() => {
    changeLang();
    setMenuOpened(false);
  }, [changeLang]);

  return (
    <header className={`app-bar ${flat ? 'app-bar--flat' : ''} ${!!menuOpened ? 'app-bar--menu-opened' : ''}`}>
      <div className="app-bar-content">
        <div className="app-bar-left">
          <button className="app-bar-menu-icon" onClick={onMenuButtonClick}>
            <ReactSVG src="/img/icon-menu.svg" />
          </button>

          <div className="app-bar-logo app-bar-logo--mobile">
            <img src="/img/logo-mobile.svg" alt="logo" />
          </div>
        </div>

        <div className="app-bar-center">
          <div className="app-bar-logo">
            <img src="/img/logo-desktop.svg" alt="logo" />
          </div>
        </div>

        <div className="app-bar-right">
          <AppBarMenu className="app-bar-menu-desktop" menu={menu} />
          <button className="app-bar-langs" onClick={changeLang}>
            <ReactSVG src={`/img/icon-flag-${newLang}.svg`} />
          </button>
          <BaseButton
            className="btn-rounded btn-warning flat"
            variant="primary"
            href={`https://account.cooperpetcare.com/?lang=${i18n.language}`}
            target="_blank"
          >
            {t('common.talk-to-vet')}
          </BaseButton>
        </div>
      </div>

      {menuOpened && (
        <div className="app-bar-mobile-menu">
          <AppBarMenu menu={menu} onClick={onMobileMenuItemClick}></AppBarMenu>
        </div>
      )}
    </header>
  );
};

export default AppBar;
