import React from 'react';
import Options from './Options';

const Question = ({ isFirstStep, currentStep, onSelect, isLoading }) => (
  <div className={`step ${currentStep.options.length > 2 ? 'step--big-answer' : ''}`}>
    <h3 className="step-title text-center">{currentStep.question}</h3>
    <Options isFirstStep={isFirstStep} options={currentStep.options} onSelect={onSelect} isLoading={isLoading} />
  </div>
);

export default Question;
