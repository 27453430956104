import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BaseButton from './BaseButton';

const BaseModal = ({ className, show = true, content, hideSubmit = false, onCancel, onSubmit, ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal className={`base-modal ${className}`} show={show} onHide={onCancel} centered {...props}>
      <Modal.Body>
        <div className="modal-image">
          <img className="modal-img" src={content.imageUrl} alt={content.title} />
        </div>
        <h3 className="modal-title">{content.title}</h3>
        <p className="modal-description">{content.description}</p>
        <div className="modal-buttons">
          <BaseButton className={'btn-rounded'} variant={'outline-secondary'} onClick={onCancel}>
            {content.cancelText || t('common.actions.cancel')}
          </BaseButton>
          {!hideSubmit && (
            <BaseButton className={'btn-rounded'} variant={'danger'} onClick={onSubmit}>
              {content.submitText || t('common.actions.submit')}
            </BaseButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BaseModal;
