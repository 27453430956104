import React from 'react';
import PropTypes from 'prop-types'
import { Spinner } from 'react-bootstrap';

const Loading = ({ className, animation, ...props }) => (
  <div className="text-center">
    <Spinner className={`base-spinner ${className}`} animation={animation} {...props} />
  </div>
);

Loading.defaultProps = {
  className: 'primary',
  animation: 'border',
};

Loading.propTypes = {
  className: PropTypes.string,
  animation: PropTypes.string,
};

export default Loading;
