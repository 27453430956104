import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import BaseProgressBar from '../Components/UI/BaseProgressBar';
import Assistant from '../Components/Assistant';
import ActionsBar from './ActionsBar';

const ChoosePet = ({ onSelect, progress }) => {
  const { t } = useTranslation();
  const selectWithDelay = (pet) => setTimeout(() => onSelect(pet), 200);

  return (
    <div className="content">
      <div className="container">
        <BaseProgressBar value={progress} rounded />
        <Assistant message={t('pages.choose-pet.assistant')} />
        <ActionsBar />

        <div className="step step--choose-pet">
          <h3 className="step-title text-center">{t('pages.choose-pet.title')}</h3>

          <div className="step-images">
            <span className="step-image">
              <ReactSVG src="/img/icon-dog.svg" />
            </span>
            <span className="step-image">
              <ReactSVG src="/img/icon-cat.svg" />
            </span>
          </div>

          <div className="base-btn-group btn-group" role="group">
            <button onClick={() => selectWithDelay('dog')} className="base-btn btn btn-primary">
              {t('common.pets.dog')}
            </button>
            <button onClick={() => selectWithDelay('cat')} className="base-btn btn btn-primary">
              {t('common.pets.cat')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ChoosePet.propTypes = {
  onSelect: PropTypes.func,
  progress: PropTypes.number
};

export default ChoosePet;
