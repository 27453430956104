export const requestParams = (method = 'GET') => {
  if (process.env.NODE_ENV !== 'production') return {};
  return {
    method: method,
    // credentials: 'same-origin',
    headers: {
      Authentication: `Basic ${btoa(process.env.REACT_APP_BASIC_AUTH)}`,
    },
  }
};
