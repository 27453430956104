import dayjs from 'dayjs';

const CETTz = 'Europe/Amsterdam';

export const toLocalDatetime = (date, time) => {
  const userTz = dayjs.tz.guess();
  const localDatetime = date && time && dayjs.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', CETTz).tz(userTz);
  return localDatetime
    ? { date: dayjs(localDatetime).format('YYYY-MM-DD'), time: dayjs(localDatetime).format('HH:mm') }
    : { date: '', time: '' };
};

export const toCETDatetime = (date, time) => {
  const CETDatetime = date && time && dayjs(`${date} ${time}`, 'YYYY-MM-DD HH:mm').tz(CETTz);
  return CETDatetime ? { date: dayjs(CETDatetime).format('YYYY-MM-DD'), time: dayjs(CETDatetime).format('HH:mm') } : { date: '', time: '' };
};
