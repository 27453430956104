import React, { useEffect, useState } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import i18n from 'i18next';
import AppBar from '../Components/AppBar';
import Workspace from '../Components/Workspace';
import { apiEndpoint } from '../../utils/api';
import { requestParams } from '../../utils/auth';
import { GTMCategory, GTMEvents } from '../lib/consts';

const CheckerPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const sendDataToGTM = useGTMDispatch();
  useEffect(() => {
    sendDataToGTM({
      event: 'event',
      eventProps: {
        category: GTMCategory,
        action: GTMEvents.landingVisit
      }
    });
  }, [sendDataToGTM]);

  const [steps, setSteps] = useState([]);
  const currentStep = () => {
    if (!steps.length) return;
    return steps[steps.length - 1][i18n.language];
  };

  const isFirstStep = () => steps.length === 1;

  const isFinalStep = () => currentStep() && !currentStep().question?.length;

  const INIT_PROGRESS = 25;
  const progress = () => {
    if (!steps.length || !disclaimerAccepted) return INIT_PROGRESS;
    if (isFinalStep()) return 100;

    return INIT_PROGRESS + steps.length * 5;
  };

  const [pet, setPet] = useState('');

  const onPetSelect = (value) => {
    setPet(value);
    initApp(value);
  };

  const [isInit, setIsInit] = useState(false);

  const initApp = async (value) => {
    setIsInit(true);
    try {
      const req = await fetch(`${apiEndpoint}/symptoms?pet=${value}`, requestParams());
      const resp = await req.json();
      setSteps([resp.data]);
    } catch (err) {
      console.log('Error:', err);
    } finally {
      setIsInit(false);
    }
  };

  const onOptionSelect = (option) => {
    if (option.question) {
      fetchNextStep(option);
    } else {
      // Add selected option as next step and show its recommendations.

      const finalOptions = finalStepOptions(option.id);
      setSteps([...steps, finalOptions]);
    }
  };

  const finalStepOptions = (optionId) => {
    const finalStep = steps[steps.length - 1];

    return Object.keys(finalStep).reduce((buff, key) => {
      return (buff = {
        ...buff,
        [key]: finalStep[key].options.find(({ id }) => id === optionId)
      });
    }, {});
  };

  const fetchNextStep = async (option) => {
    setIsLoading(true);
    try {
      const req = await fetch(`${apiEndpoint}/symptoms/${option.id}`, requestParams());
      const resp = await req.json();
      setSteps([...steps, resp.data]);
    } catch (err) {
      console.log('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onBack = () => {
    if (steps.length === 1) {
      setPet('');
    }
    setSteps(steps.slice(0, -1));
  };

  const onRestart = () => {
    setSteps([]);
    setPet('');
  };

  return (
    <>
      <AppBar />
      <Workspace
        pet={pet}
        onPetSelect={onPetSelect}
        disclaimerAccepted={disclaimerAccepted}
        setDisclaimerAccepted={setDisclaimerAccepted}
        progress={progress()}
        isInit={isInit}
        isLoading={isLoading}
        isFirstStep={isFirstStep()}
        isLastStep={isFinalStep()}
        currentStep={currentStep()}
        onOptionSelect={onOptionSelect}
        onBack={!!pet ? onBack : null}
        onRestart={onRestart}
      />
    </>
  );
};

export default CheckerPage;
