import { useState, useEffect } from 'react';

export const laptopQuery = '(max-width: 1366px)';
export const tabletQuery = '(max-width: 1024px)';
export const mobileQuery = '(max-width: 767px)';

export const useMediaQuery = (query) => {
  const mediaQuery = typeof window !== 'undefined' ? window.matchMedia(query) : null;
  const [match, setMatch] = useState(!!mediaQuery?.matches);

  useEffect(() => {
    if (!mediaQuery) {
      return;
    }

    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, [mediaQuery]);

  return match;
};
