import React, { useState, useMemo, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import BaseInput from './BaseInput';

const BaseSelect = ({
  className = '',
  label,
  placeholder = 'placeholder',
  options,
  persistentPlaceholder,
  filterDisabled = false,
  value,
  isInvalid,
  hint,
  noDataText,
  onSelect
}) => {
  const [opened, setOpened] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const optionsByValue = useMemo(() => {
    return options.reduce((acc, option) => {
      acc[option.value] = option;
      return acc;
    }, {});
  }, [options]);

  const displayValue = useMemo(() => {
    return optionsByValue[value]?.text || '';
  }, [optionsByValue, value]);

  const filteredOptions = useMemo(() => {
    if (!filterValue) {
      return options;
    }

    return options.filter((option) => option.text.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()));
  }, [options, filterValue]);

  useEffect(() => {
    setFilterValue('');
  }, [options]);

  const handleSelect = (option) => {
    onSelect(option.value);
    setFilterValue(option.text);
  };

  const handleKeydown = (keyCode, option) => {
    if (keyCode === 13) {
      handleSelect(option);
    }
  };

  return (
    <Dropdown
      className={`base-select ${value ? 'base-select--selected' : ''} ${className}`}
      onToggle={(dropdownOpened) => setOpened(dropdownOpened)}
    >
      <Dropdown.Toggle
        as={BaseInput}
        label={label}
        placeholder={placeholder}
        persistentPlaceholder={persistentPlaceholder}
        readOnly={!opened || filterDisabled}
        value={opened ? filterValue : displayValue}
        isInvalid={isInvalid}
        hint={hint}
        onChange={(value) => setFilterValue(value)}
      />

      <span className="base-select-arrow">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.88 9.29L12 13.17 8.12 9.29a.996.996 0 10-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 000-1.41c-.39-.38-1.03-.39-1.42 0z"
            fill="currentColor"
          />
        </svg>
      </span>

      <Dropdown.Menu
        className="base-select-menu"
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
        }}
      >
        {noDataText && (
          <Dropdown.Item className="base-select-option" key="no-data">
            {noDataText}
          </Dropdown.Item>
        )}
        {filteredOptions.map((option) => (
          <Dropdown.Item
            key={option.value}
            className="base-select-option"
            onKeyDown={(e) => handleKeydown(e.keyCode, option)}
            onClick={() => handleSelect(option)}
          >
            {option.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BaseSelect;
