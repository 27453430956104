import React from 'react';
import { Button } from 'react-bootstrap';

const BaseButton = ({ type = 'button', children, className, onClick, variant, ...props }) => (
  <Button
    type={type}
    className={`base-btn ${className}`}
    variant={variant}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
);

export default BaseButton;
